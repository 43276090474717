import { Moment } from "moment";
import { ProjectModalTab } from "../../components/Modals/ProjectModal";
import { ProductModalTab } from "../../components/Modals/ProductModal";
import { AttachmentViewerAction } from "../../components/Modals/AttachmentsViewerModal";
import { UserWorkspaceSettingModalTab } from "../../components/Modals/UserWorkspaceSettingModal";
import { DealModalTab } from "../../components/Modals/DealModal";
import { ShareableLink } from "../../components/Modals/ShareLinksModal";
import {
  Accountant,
  BizzeyFile,
  BizzeyFileResourceType,
  Board,
  CalendarEvent,
  Call,
  Contact,
  ContactGroup,
  ContactType,
  ContentBlockTemplate,
  CustomField,
  Deal,
  DealStage,
  Document,
  DocumentTag,
  Email,
  SettingsEmailTemplate,
  Expense,
  ExpenseCategory,
  FinancialYear,
  GroupedTimeEntries,
  ImportedProduct,
  ItemBlock,
  ItemsBlock,
  LedgerItem,
  LedgerItemPayment,
  Payment,
  PaymentInitiation,
  PeppolParticipant,
  PlaybookSubmission,
  PreviewAttachment,
  Product,
  Project,
  ProjectStatus,
  QuestionAndAnswerBlock,
  RemittanceInformationType,
  SendEmailParams,
  SignatureResult,
  Task,
  TimeEntry,
  TimeEntryExportType,
  Transaction,
  UserWorkspaceSetting,
  Workspace,
  WorkspaceTax,
  WorkType,
  EmailTemplate,
} from "../../types";
import { TransactionModalTab } from "../../components/Modals/TransactionModal";
import { ContactModalTab } from "../../components/Modals/ContactModal";

export const SHOW_CONTACT_MODAL = "SHOW_CONTACT_MODAL";
export const CLOSE_CONTACT_MODAL = "CLOSE_CONTACT_MODAL";
export const SHOW_PROJECT_MODAL = "SHOW_PROJECT_MODAL";
export const CLOSE_PROJECT_MODAL = "CLOSE_PROJECT_MODAL";
export const SHOW_PROJECT_STATUS_UPDATE_MODAL =
  "SHOW_PROJECT_STATUS_UPDATE_MODAL";
export const CLOSE_PROJECT_STATUS_UPDATE_MODAL =
  "CLOSE_PROJECT_STATUS_UPDATE_MODAL";
export const SHOW_EXPENSE_MODAL = "SHOW_EXPENSE_MODAL";
export const CLOSE_EXPENSE_MODAL = "CLOSE_EXPENSE_MODAL";
export const SHOW_TIME_ENTRY_EXPORT_MODAL = "SHOW_TIME_ENTRY_EXPORT_MODAL";
export const CLOSE_TIME_ENTRY_EXPORT_MODAL = "CLOSE_TIME_ENTRY_EXPORT_MODAL";
export const SHOW_TIME_ENTRY_MODAL = "SHOW_TIME_ENTRY_MODAL";
export const CLOSE_TIME_ENTRY_MODAL = "CLOSE_TIME_ENTRY_MODAL";
export const SHOW_SEND_LEDGER_ITEM_MODAL = "SHOW_SEND_LEDGER_ITEM_MODAL";
export const CLOSE_SEND_LEDGER_ITEM_MODAL = "CLOSE_SEND_LEDGER_ITEM_MODAL";
export const SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL =
  "SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL";
export const CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL =
  "CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL";
export const SHOW_PAYMENT_DETAILS_MODAL = "SHOW_PAYMENT_DETAILS_MODAL";
export const CLOSE_PAYMENT_DETAILS_MODAL = "CLOSE_PAYMENT_DETAILS_MODAL";
export const SHOW_IMPORT_PRODUCTS_MODAL = "SHOW_IMPORT_PRODUCTS_MODAL";
export const CLOSE_IMPORT_PRODUCTS_MODAL = "CLOSE_IMPORT_PRODUCTS_MODAL";
export const SHOW_IMPORT_TIME_ENTRIES_MODAL = "SHOW_IMPORT_TIME_ENTRIES_MODAL";
export const CLOSE_IMPORT_TIME_ENTRIES_MODAL =
  "CLOSE_IMPORT_TIME_ENTRIES_MODAL";
export const SHOW_IMPORT_EXPENSES_MODAL = "SHOW_IMPORT_EXPENSES_MODAL";
export const CLOSE_IMPORT_EXPENSES_MODAL = "CLOSE_IMPORT_EXPENSES_MODAL";
export const SHOW_TAXES_MODAL = "SHOW_TAXES_MODAL";
export const CLOSE_TAXES_MODAL = "CLOSE_TAXES_MODAL";
export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";
export const SHOW_PRODUCT_MODAL = "SHOW_PRODUCT_MODAL";
export const CLOSE_PRODUCT_MODAL = "CLOSE_PRODUCT_MODAL";
export const SHOW_MOBILE_APP_MODAL = "SHOW_MOBILE_APP_MODAL";
export const CLOSE_MOBILE_APP_MODAL = "CLOSE_MOBILE_APP_MODAL";
export const SHOW_FILE_MODAL = "SHOW_FILE_MODAL";
export const CLOSE_FILE_MODAL = "CLOSE_FILE_MODAL";
export const SHOW_MOVE_FILES_MODAL = "SHOW_MOVE_FILES_MODAL";
export const CLOSE_MOVE_FILES_MODAL = "CLOSE_MOVE_FILES_MODAL";
export const SHOW_EXPORT_LEDGER_ITEMS_MODAL = "SHOW_EXPORT_LEDGER_ITEMS_MODAL";
export const CLOSE_EXPORT_LEDGER_ITEMS_MODAL =
  "CLOSE_EXPORT_LEDGER_ITEMS_MODAL";
export const SHOW_EXPORT_EXPENSES_MODAL = "SHOW_EXPORT_EXPENSES_MODAL";
export const CLOSE_EXPORT_EXPENSES_MODAL = "CLOSE_EXPORT_EXPENSES_MODAL";
export const SHOW_EXPENSE_CATEGORY_MODAL = "SHOW_EXPENSE_CATEGORY_MODAL";
export const CLOSE_EXPENSE_CATEGORY_MODAL = "CLOSE_EXPENSE_CATEGORY_MODAL";
export const SHOW_EXPORT_PRODUCTS_MODAL = "SHOW_EXPORT_PRODUCTS_MODAL";
export const CLOSE_EXPORT_PRODUCTS_MODAL = "CLOSE_EXPORT_PRODUCTS_MODAL";
export const SHOW_CUSTOM_FIELD_MODAL = "SHOW_CUSTOM_FIELD_MODAL";
export const CLOSE_CUSTOM_FIELD_MODAL = "CLOSE_CUSTOM_FIELD_MODAL";
export const SHOW_BOARD_MODAL = "SHOW_BOARD_MODAL";
export const CLOSE_BOARD_MODAL = "CLOSE_BOARD_MODAL";
export const SHOW_TASK_MODAL = "SHOW_TASK_MODAL";
export const CLOSE_TASK_MODAL = "CLOSE_TASK_MODAL";
export const SHOW_CALENDAR_EVENT_MODAL = "SHOW_CALENDAR_EVENT_MODAL";
export const CLOSE_CALENDAR_EVENT_MODAL = "CLOSE_CALENDAR_EVENT_MODAL";
export const SHOW_ATTACHMENTS_VIEWER_MODAL = "SHOW_ATTACHMENTS_VIEWER_MODAL";
export const CLOSE_ATTACHMENTS_VIEWER_MODAL = "CLOSE_ATTACHMENTS_VIEWER_MODAL";
export const SHOW_ADD_ACCOUNTANT_MODAL = "SHOW_ADD_ACCOUNTANT_MODAL";
export const CLOSE_ADD_ACCOUNTANT_MODAL = "CLOSE_ADD_ACCOUNTANT_MODAL";
export const SHOW_USER_WORKSPACE_SETTING_MODAL =
  "SHOW_USER_WORKSPACE_SETTING_MODAL";
export const CLOSE_USER_WORKSPACE_SETTING_MODAL =
  "CLOSE_USER_WORKSPACE_SETTING_MODAL";
export const SHOW_BULK_TIME_ENTRY_MODAL = "SHOW_BULK_TIME_ENTRY_MODAL";
export const CLOSE_BULK_TIME_ENTRY_MODAL = "CLOSE_BULK_TIME_ENTRY_MODAL";
export const SHOW_CALCULATE_ADVANCE_MODAL = "SHOW_CALCULATE_ADVANCE_MODAL";
export const CLOSE_CALCULATE_ADVANCE_MODAL = "CLOSE_CALCULATE_ADVANCE_MODAL";
export const SHOW_SEND_CONTRACT_MODAL = "SHOW_SEND_CONTRACT_MODAL";
export const CLOSE_SEND_CONTRACT_MODAL = "CLOSE_SEND_CONTRACT_MODAL";
export const SHOW_SHARE_LINKS_MODAL = "SHOW_SHARE_LINKS_MODAL";
export const CLOSE_SHARE_LINKS_MODAL = "CLOSE_SHARE_LINKS_MODAL";
export const SHOW_SEND_PROPOSAL_MODAL = "SHOW_SEND_PROPOSAL_MODAL";
export const CLOSE_SEND_PROPOSAL_MODAL = "CLOSE_SEND_PROPOSAL_MODAL";
export const SHOW_ITEMS_BLOCK_SETTING_MODAL = "SHOW_ITEMS_BLOCK_SETTING_MODAL";
export const CLOSE_ITEMS_BLOCK_SETTING_MODAL =
  "CLOSE_ITEMS_BLOCK_SETTING_MODAL";
export const SHOW_ITEM_BLOCK_SETTING_MODAL = "SHOW_ITEM_BLOCK_SETTING_MODAL";
export const CLOSE_ITEM_BLOCK_SETTING_MODAL = "CLOSE_ITEM_BLOCK_SETTING_MODAL";
export const SHOW_CONTENT_BLOCK_TEMPLATE_MODAL =
  "SHOW_CONTENT_BLOCK_TEMPLATE_MODAL";
export const CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL =
  "CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL";
export const SHOW_DIRECTIONS_MODAL = "SHOW_DIRECTIONS_MODAL";
export const CLOSE_DIRECTIONS_MODAL = "CLOSE_DIRECTIONS_MODAL";
export const SHOW_LEDGER_ITEM_PAYMENT_MODAL = "SHOW_LEDGER_ITEM_PAYMENT_MODAL";
export const CLOSE_LEDGER_ITEM_PAYMENT_MODAL =
  "CLOSE_LEDGER_ITEM_PAYMENT_MODAL";
export const SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL =
  "SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL";
export const CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL =
  "CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL";
export const SHOW_LEDGER_ITEM_CLAIM_MODAL = "SHOW_LEDGER_ITEM_CLAIM_MODAL";
export const CLOSE_LEDGER_ITEM_CLAIM_MODAL = "CLOSE_LEDGER_ITEM_CLAIM_MODAL";
export const SHOW_PAYMENT_MODAL = "SHOW_PAYMENT_MODAL";
export const CLOSE_PAYMENT_MODAL = "CLOSE_PAYMENT_MODAL";
export const SHOW_PAYMENT_INITIATION_MODAL = "SHOW_PAYMENT_INITIATION_MODAL";
export const CLOSE_PAYMENT_INITIATION_MODAL = "CLOSE_PAYMENT_INITIATION_MODAL";
export const SHOW_DEAL_MODAL = "SHOW_DEAL_MODAL";
export const CLOSE_DEAL_MODAL = "CLOSE_DEAL_MODAL";
export const SHOW_DEAL_STAGE_MODAL = "SHOW_DEAL_STAGE_MODAL";
export const CLOSE_DEAL_STAGE_MODAL = "CLOSE_DEAL_STAGE_MODAL";
export const SHOW_TAX_MODAL = "SHOW_TAX_MODAL";
export const CLOSE_TAX_MODAL = "CLOSE_TAX_MODAL";
export const SHOW_WORK_TYPE_MODAL = "SHOW_WORK_TYPE_MODAL";
export const CLOSE_WORK_TYPE_MODAL = "CLOSE_WORK_TYPE_MODAL";
export const SHOW_DISCOUNT_MODAL = "SHOW_DISCOUNT_MODAL";
export const CLOSE_DISCOUNT_MODAL = "CLOSE_DISCOUNT_MODAL";
export const SHOW_PEPPOL_PARTICIPANT_MODAL = "SHOW_PEPPOL_PARTICIPANT_MODAL";
export const CLOSE_PEPPOL_PARTICIPANT_MODAL = "CLOSE_PEPPOL_PARTICIPANT_MODAL";
export const SHOW_FINANCIAL_YEAR_MODAL = "SHOW_FINANCIAL_YEAR_MODAL";
export const CLOSE_FINANCIAL_YEAR_MODAL = "CLOSE_FINANCIAL_YEAR_MODAL";
export const SHOW_TRANSACTION_MODAL = "SHOW_TRANSACTION_MODAL";
export const CLOSE_TRANSACTION_MODAL = "CLOSE_TRANSACTION_MODAL";
export const SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL =
  "SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL";
export const CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL =
  "CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL";
export const SHOW_PLAYBOOK_SUBMISSION_MODAL = "SHOW_PLAYBOOK_SUBMISSION_MODAL";
export const CLOSE_PLAYBOOK_SUBMISSION_MODAL =
  "CLOSE_PLAYBOOK_SUBMISSION_MODAL";
export const SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL =
  "SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL";
export const CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL =
  "CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL";
export const SHOW_SIGNATURE_MODAL = "SHOW_SIGNATURE_MODAL";
export const CLOSE_SIGNATURE_MODAL = "CLOSE_SIGNATURE_MODAL";
export const SHOW_EMAIL_VIEWER_MODAL = "SHOW_EMAIL_VIEWER_MODAL";
export const CLOSE_EMAIL_VIEWER_MODAL = "CLOSE_EMAIL_VIEWER_MODAL";
export const SHOW_CONTACT_GROUP_MODAL = "SHOW_CONTACT_GROUP_MODAL";
export const CLOSE_CONTACT_GROUP_MODAL = "CLOSE_CONTACT_GROUP_MODAL";
export const SHOW_BULK_CONTACT_GROUP_MODAL = "SHOW_BULK_CONTACT_GROUP_MODAL";
export const CLOSE_BULK_CONTACT_GROUP_MODAL = "CLOSE_BULK_CONTACT_GROUP_MODAL";
export const SHOW_EXPORT_CONTACTS_MODAL = "SHOW_EXPORT_CONTACTS_MODAL";
export const CLOSE_EXPORT_CONTACTS_MODAL = "CLOSE_EXPORT_CONTACTS_MODAL";
export const SHOW_CONTACT_TYPE_MODAL = "SHOW_CONTACT_TYPE_MODAL";
export const CLOSE_CONTACT_TYPE_MODAL = "CLOSE_CONTACT_TYPE_MODAL";
export const SHOW_FILE_UPLOAD_MODAL = "SHOW_FILE_UPLOAD_MODAL";
export const CLOSE_FILE_UPLOAD_MODAL = "CLOSE_FILE_UPLOAD_MODAL";
export const SHOW_PAYMENT_QR_MODAL = "SHOW_PAYMENT_QR_MODAL";
export const CLOSE_PAYMENT_QR_MODAL = "CLOSE_PAYMENT_QR_MODAL";
export const SHOW_CUSTOM_COMPONENT_MODAL = "SHOW_CUSTOM_COMPONENT_MODAL";
export const CLOSE_CUSTOM_COMPONENT_MODAL = "CLOSE_CUSTOM_COMPONENT_MODAL";
export const SHOW_DOCUMENT_MODAL = "SHOW_DOCUMENT_MODAL";
export const CLOSE_DOCUMENT_MODAL = "CLOSE_DOCUMENT_MODAL";
export const SHOW_DOCUMENT_TAG_MODAL = "SHOW_DOCUMENT_TAG_MODAL";
export const CLOSE_DOCUMENT_TAG_MODAL = "CLOSE_DOCUMENT_TAG_MODAL";
export const SHOW_EXPORT_DOCUMENTS_MODAL = "SHOW_EXPORT_DOCUMENTS_MODAL";
export const CLOSE_EXPORT_DOCUMENTS_MODAL = "CLOSE_EXPORT_DOCUMENTS_MODAL";
export const SHOW_UPLOAD_DOCUMENTS_MODAL = "SHOW_UPLOAD_DOCUMENTS_MODAL";
export const CLOSE_UPLOAD_DOCUMENTS_MODAL = "CLOSE_UPLOAD_DOCUMENTS_MODAL";
export const SHOW_PRODUCT_IMPORT_MODAL = "SHOW_PRODUCT_IMPORT_MODAL";
export const CLOSE_PRODUCT_IMPORT_MODAL = "CLOSE_PRODUCT_IMPORT_MODAL";
export const SHOW_CALL_MODAL = "SHOW_CALL_MODAL";
export const CLOSE_CALL_MODAL = "CLOSE_CALL_MODAL";
export const SHOW_SEND_EMAIL_MODAL = "SHOW_SEND_EMAIL_MODAL";
export const CLOSE_SEND_EMAIL_MODAL = "CLOSE_SEND_EMAIL_MODAL";
export const SHOW_EMAIL_TEMPLATE_MODAL = "SHOW_EMAIL_TEMPLATE_MODAL";
export const CLOSE_EMAIL_TEMPLATE_MODAL = "CLOSE_EMAIL_TEMPLATE_MODAL";

export interface ModalsState {
  contactModal: {
    show: boolean;
    contact?: Contact;
    activeTab?: ContactModalTab;
    onSubmit?: (contact: Contact) => void;
  };
  projectModal: {
    show: boolean;
    project?: Project;
    contactDisabled?: boolean;
    statusDisabled?: boolean;
    activeTab?: ProjectModalTab;
    onSubmit?: (project: Project) => void;
  };
  projectStatusUpdateModal: {
    show: boolean;
    project?: Project;
    onSubmit?: (project: Project) => void;
  };
  expenseModal: {
    show: boolean;
    expense?: Expense;
    supplierDisabled?: boolean;
    contactDisabled?: boolean;
    projectDisabled?: boolean;
    onSubmit?: (expense: Expense) => void;
  };
  timeEntryExportModal: {
    show: boolean;
    contactId?: string;
    contactDisabled?: boolean;
    projectId?: string;
    projectDisabled?: boolean;
    start?: Moment;
    end?: Moment;
    type?: TimeEntryExportType;
    onSubmit?: () => void;
  };
  timeEntryModal: {
    show: boolean;
    timeEntry?: TimeEntry;
    contactDisabled?: boolean;
    projectDisabled?: boolean;
    onSubmit?: (timeEntry: TimeEntry) => void;
  };
  bulkTimeEntryModal: {
    show: boolean;
    contactId?: string;
    contactDisabled?: boolean;
    projectId?: string;
    projectDisabled?: boolean;
    onSubmit?: (timeEntries: TimeEntry[]) => void;
  };
  sendLedgerItemModal: {
    show: boolean;
    id: string;
    reminder: boolean;
    onSubmit?: (ledgerItem: LedgerItem) => void;
  };
  settingsEmailTemplateModal: {
    show: boolean;
    template?: SettingsEmailTemplate;
  };
  paymentDetailsModal: {
    show: boolean;
    onSubmit?: (workspace: Workspace) => void;
  };
  importProductsModal: {
    show: boolean;
    onSubmit?: (products: ImportedProduct[]) => void;
  };
  importTimeEntriesModal: {
    show: boolean;
    onSubmit?: (groupedTimeEntries: GroupedTimeEntries[]) => void;
    contactId?: string;
    projectId?: string;
  };
  importExpensesModal: {
    show: boolean;
    onSubmit?: (expenses: Expense[]) => void;
    contactId?: string;
    projectId?: string;
  };
  taxesModal: {
    show: boolean;
    onSubmit?: (taxes: WorkspaceTax[]) => void;
  };
  confirmModal: {
    show: boolean;
    title: string;
    description: string | any;
    action: { label: string; isDestructive?: boolean };
    onConfirm: () => void;
    onCancel?: () => void;
  };
  productModal: {
    show: boolean;
    product?: Product;
    activeTab?: ProductModalTab;
    supplierDisabled?: boolean;
    onSubmit?: (product: Product) => void;
  };
  mobileAppModal: {
    show: boolean;
  };
  fileModal: {
    show: boolean;
    file?: BizzeyFile;
    baseResourcePath?: string;
    onSubmit?: (file: BizzeyFile) => void;
  };
  moveFilesModal: {
    show: boolean;
    fileIds: string[];
    baseResourcePath?: string;
    onSubmit?: () => void;
  };
  exportLedgerItemsModal: {
    show: boolean;
    onSubmit?: () => void;
  };
  exportExpensesModal: {
    show: boolean;
    onSubmit?: () => void;
  };
  expenseCategoryModal: {
    show: boolean;
    category?: ExpenseCategory;
    onSubmit?: (category: ExpenseCategory) => void;
  };
  exportProductsModal: {
    show: boolean;
    onSubmit?: () => void;
  };
  customFieldModal: {
    show: boolean;
    customField?: CustomField;
    typeDisabled?: boolean;
    modelDisabled?: boolean;
    onSubmit?: (customField: CustomField) => void;
  };
  taskModal: {
    show: boolean;
    task?: Task;
    contactDisabled?: boolean;
    projectDisabled?: boolean;
    onSubmit?: (task: Task) => void;
    onDelete?: (task: Task) => void;
  };
  boardModal: {
    show: boolean;
    board?: Board;
    contactDisabled?: boolean;
    projectDisabled?: boolean;
    onSubmit?: (board: Board) => void;
  };
  calendarEventModal: {
    show: boolean;
    calendarEvent?: CalendarEvent;
    contactDisabled?: boolean;
    projectDisabled?: boolean;
    onSubmit?: (calendarEvent: CalendarEvent) => void;
    onDelete?: (calendarEventid: string) => void;
  };
  attachmentsViewerModal: {
    show: boolean;
    activeIndex?: number;
    attachments?: PreviewAttachment[];
    actions?: AttachmentViewerAction[];
    onDelete?: () => void;
  };
  addAccountantModal: {
    show: boolean;
    onSubmit?: (accountant: Accountant) => void;
  };
  userWorkspaceSettingModal: {
    show: boolean;
    activeTab?: UserWorkspaceSettingModalTab;
    userWorkspaceSetting?: UserWorkspaceSetting;
    onSubmit?: (userWorkspaceSetting: UserWorkspaceSetting) => void;
  };
  calculateAmountModal: {
    show: boolean;
    title?: string;
    amount: number;
    onSubmit?: (amount: Number) => void;
  };
  sendContractModal: {
    show: boolean;
    id?: string;
    onSubmit?: () => void;
  };
  shareLinksModal: {
    show: boolean;
    title?: string;
    shareableLinks?: ShareableLink[];
  };
  sendProposalModal: {
    show: boolean;
    id?: string;
    onSubmit?: () => void;
  };
  itemsBlockSettingModal: {
    show: boolean;
    itemsBlock?: ItemsBlock;
    onSubmit?: (block: ItemsBlock) => void;
  };
  itemBlockSettingModal: {
    show: boolean;
    itemBlock?: ItemBlock;
    onSubmit?: (itemBlock: ItemBlock) => void;
  };
  directionsModal: {
    show: boolean;
    origin?: string;
    destination?: string;
    onSubmit?: (result: DirectionsModalResult) => void;
  };
  ledgerItemPaymentModal: {
    show: boolean;
    ledgerItemPaymentId?: string;
    ledgerItemId?: string;
    onSubmit?: (ledgerItemPayment: LedgerItemPayment) => void;
  };
  ledgerItemTransactionHistoryModal: {
    show: boolean;
    ledgerItemId?: string;
    onSync?: () => void;
  };
  ledgerItemClaimModal: {
    show: boolean;
    ledgerItemId?: string;
    onSubmit?: () => void;
  };
  paymentModal: {
    show: boolean;
    payment?: Payment;
    onSubmit?: (payment: Payment) => void;
  };
  paymentInitiationModal: {
    show: boolean;
    paymentInitiation?: PaymentInitiation;
    onSubmit?: (paymentInitiation: PaymentInitiation) => void;
    onDelete?: (paymentInitiation: PaymentInitiation) => void;
  };
  dealModal: {
    show: boolean;
    deal?: Deal;
    contactDisabled?: boolean;
    projectDisabled?: boolean;
    activeTab?: DealModalTab;
    onSubmit?: (deal: Deal) => void;
  };
  dealStageModal: {
    show: boolean;
    dealStage?: DealStage;
    onSubmit?: (dealStage: DealStage) => void;
  };
  contentBlockTemplateModal: {
    show: boolean;
    contentBlockTemplate?: ContentBlockTemplate;
    contentBlockId: string;
    onSubmit?: (contentBlockTemplate: ContentBlockTemplate) => void;
    onDelete?: (contentBlockTemplate: ContentBlockTemplate) => void;
  };
  taxModal: {
    show: boolean;
    tax?: WorkspaceTax;
    onSubmit?: (tax: WorkspaceTax) => void;
  };
  workTypeModal: {
    show: boolean;
    workType?: WorkType;
    onSubmit?: (workType: WorkType) => void;
  };
  discountModal: {
    show: boolean;
    currency?: string;
    total?: number;
    discount?: number;
    onSubmit?: (discount: number) => void;
  };
  peppolParticipantModal: {
    show: boolean;
    query?: string;
    onSubmit?: (participant: PeppolParticipant) => void;
  };
  financialYearModal: {
    show: boolean;
    financialYear?: FinancialYear;
    onSubmit?: (financialYear: FinancialYear) => void;
  };
  transactionModal: {
    show: boolean;
    activeTab?: TransactionModalTab;
    transaction?: Transaction;
    onSubmit?: (transaction: Transaction) => void;
  };
  questionAndAnswerBlockSettingModal: {
    show: boolean;
    questionAndAnswerBlock?: QuestionAndAnswerBlock;
    onSubmit?: (block: QuestionAndAnswerBlock) => void;
  };
  playbookSubmissionModal: {
    show: boolean;
    playbookSubmission?: PlaybookSubmission;
    onSubmit?: (
      playbookSubmission: PlaybookSubmission,
      call?: Call,
      task?: Task
    ) => void;
  };
  bulkProjectStatusUpdateModal: {
    show: boolean;
    excludeStatuses?: ProjectStatus[];
    onSubmit?: (status: ProjectStatus) => void;
  };
  signatureModal: {
    show: boolean;
    onSubmit?: (signature: SignatureResult) => void;
    onClose?: () => void;
  };
  emailViewerModal: {
    show: boolean;
    email?: Email;
  };
  contactGroupModal: {
    show: boolean;
    contactGroup?: ContactGroup;
    onSubmit?: (contactGroup: ContactGroup) => void;
  };
  bulkContactGroupModal: {
    show: boolean;
    action?: "add" | "remove";
    contactIds?: string[];
    onSubmit?: (contactGroups: ContactGroup[]) => void;
  };
  exportContactsModal: {
    show: boolean;
    onSubmit?: () => void;
  };
  contactTypeModal: {
    show: boolean;
    onSubmit?: (contactType: ContactType) => void;
  };
  fileUploadModal: {
    show: boolean;
    resourceType?: BizzeyFileResourceType;
    resourceId?: string;
    onSubmit?: (files: BizzeyFile[]) => void;
  };
  paymentQRModal: {
    show: boolean;
    name?: string;
    iban?: string;
    remittanceInformation?: string;
    remittanceInformationType?: RemittanceInformationType;
    amount?: number;
    currency?: string;
    onSubmit?: (paid: boolean) => void;
  };
  customComponentModal: {
    show: boolean;
    title?: string;
    component?: JSX.Element;
  };
  documentModal: {
    show: boolean;
    document?: Document;
    onSubmit?: (document: Document) => void;
  };
  documentTagModal: {
    show: boolean;
    documentTag?: DocumentTag;
    onSubmit?: (documentTag: DocumentTag) => void;
    onDelete?: (documentTag: DocumentTag) => void;
  };
  exportDocumentsModal: {
    show: boolean;
    onSubmit?: () => void;
  };
  uploadDocumentsModal: {
    show: boolean;
    onSubmit?: (documents: Document[]) => void;
  };
  productImportModal: {
    show: boolean;
    onSubmit?: (blob: ActiveStorage.Blob) => void;
  };
  callModal: {
    show: boolean;
    call?: Call;
    onSubmit?: (call: Call) => void;
  };
  sendEmailModal: {
    show: boolean;
    email?: SendEmailParams;
    onSubmit?: () => void;
  };
  emailTemplateModal: {
    show: boolean;
    emailTemplate?: EmailTemplate;
    onSubmit?: (emailTemplate: EmailTemplate) => void;
  };
}

export interface ContactModalOptions {
  contact?: Contact;
  activeTab?: ContactModalTab;
  onSubmit?: (contact: Contact) => void;
}

interface ShowContactModalAction extends ContactModalOptions {
  type: typeof SHOW_CONTACT_MODAL;
}

interface HideContactModalAction {
  type: typeof CLOSE_CONTACT_MODAL;
}

export interface ProjectModalOptions {
  project?: Project;
  contactDisabled?: boolean;
  statusDisabled?: boolean;
  activeTab?: ProjectModalTab;
  onSubmit?: (project: Project) => void;
}

interface ShowProjectModalAction extends ProjectModalOptions {
  type: typeof SHOW_PROJECT_MODAL;
}

interface HideProjectModalAction {
  type: typeof CLOSE_PROJECT_MODAL;
}

export interface ProjectStatusUpdateModalOptions {
  project?: Project;
  onSubmit?: (project: Project) => void;
}

interface ShowProjectStatusUpdateModalAction
  extends ProjectStatusUpdateModalOptions {
  type: typeof SHOW_PROJECT_STATUS_UPDATE_MODAL;
}

interface HideProjectStatusUpdateModalAction {
  type: typeof CLOSE_PROJECT_STATUS_UPDATE_MODAL;
}

export interface ExpenseModalOptions {
  expense?: Expense;
  supplierDisabled?: boolean;
  contactDisabled?: boolean;
  projectDisabled?: boolean;
  onSubmit?: (expense: Expense) => void;
}

interface ShowExpenseModalAction extends ExpenseModalOptions {
  type: typeof SHOW_EXPENSE_MODAL;
}

interface HideExpenseModalAction {
  type: typeof CLOSE_EXPENSE_MODAL;
}

export interface TimeEntryExportModalOptions {
  contactId?: string;
  contactDisabled?: boolean;
  projectId?: string;
  projectDisabled?: boolean;
  start?: Moment;
  end?: Moment;
  exportType?: TimeEntryExportType;
  onSubmit?: () => void;
}

interface ShowTimeEntryExportModalAction extends TimeEntryExportModalOptions {
  type: typeof SHOW_TIME_ENTRY_EXPORT_MODAL;
}

interface HideTimeEntryExportModalAction {
  type: typeof CLOSE_TIME_ENTRY_EXPORT_MODAL;
}

export interface TimeEntryModalOptions {
  timeEntry?: TimeEntry;
  contactDisabled?: boolean;
  projectDisabled?: boolean;
  onSubmit?: (timeEntry: TimeEntry) => void;
}
interface ShowTimeEntryModalAction extends TimeEntryModalOptions {
  type: typeof SHOW_TIME_ENTRY_MODAL;
}

interface HideTimeEntryModalAction {
  type: typeof CLOSE_TIME_ENTRY_MODAL;
}

export interface ShowSendLedgerItemModalOptions {
  ledgerItemId?: string;
  reminder?: boolean;
  onSubmit?: (ledgerItem: LedgerItem) => void;
}

interface ShowSendLedgerItemModalAction extends ShowSendLedgerItemModalOptions {
  type: typeof SHOW_SEND_LEDGER_ITEM_MODAL;
}

interface HideSendLedgerItemModalAction {
  type: typeof CLOSE_SEND_LEDGER_ITEM_MODAL;
}

export interface SettingsEmailTemplateModalOptions {
  template?: SettingsEmailTemplate;
}

interface ShowSettingsEmailTemplateModalAction
  extends SettingsEmailTemplateModalOptions {
  type: typeof SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL;
}

interface HideSettingsEmailTemplateModalAction {
  type: typeof CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL;
}

interface ShowPaymentDetailsModalAction {
  type: typeof SHOW_PAYMENT_DETAILS_MODAL;
  onSubmit?: (workspace: Workspace) => void;
}

interface HidePaymentDetailsModalAction {
  type: typeof CLOSE_PAYMENT_DETAILS_MODAL;
}

interface ShowImportProductsModalAction {
  type: typeof SHOW_IMPORT_PRODUCTS_MODAL;
  onSubmit?: (importedProducts: ImportedProduct[]) => void;
}

interface HideImportProductsModalAction {
  type: typeof CLOSE_IMPORT_PRODUCTS_MODAL;
}

interface ShowImportTimeEntriesModalAction {
  type: typeof SHOW_IMPORT_TIME_ENTRIES_MODAL;
  onSubmit?: (groupedTimeEntries: GroupedTimeEntries[]) => void;
  contactId?: string;
  projectId?: string;
}

interface HideImportTimeEntriesModalAction {
  type: typeof CLOSE_IMPORT_TIME_ENTRIES_MODAL;
}

interface ShowImportExpensesModalAction {
  type: typeof SHOW_IMPORT_EXPENSES_MODAL;
  onSubmit?: (expenses: Expense[]) => void;
  contactId?: string;
  projectId?: string;
}

interface HideImportExpensesModalAction {
  type: typeof CLOSE_IMPORT_EXPENSES_MODAL;
}

interface ShowTaxesModalAction {
  type: typeof SHOW_TAXES_MODAL;
  onSubmit?: (taxes: WorkspaceTax[]) => void;
}

interface HideTaxesModalAction {
  type: typeof CLOSE_TAXES_MODAL;
}

interface ShowConfirmModalAction {
  type: typeof SHOW_CONFIRM_MODAL;
  title: string;
  description: string | any;
  action: { label: string; isDestructive?: boolean };
  onConfirm: () => void;
  onCancel?: () => void;
}

interface HideConfirmModalAction {
  type: typeof CLOSE_CONFIRM_MODAL;
}

export interface ProductModalOptions {
  product?: Product;
  activeTab?: ProductModalTab;
  supplierDisabled?: boolean;
  onSubmit?: (product: Product) => void;
}

interface ShowProductModalAction extends ProductModalOptions {
  type: typeof SHOW_PRODUCT_MODAL;
}

interface HideProductModalAction {
  type: typeof CLOSE_PRODUCT_MODAL;
}

interface ShowMobileAppModalAction {
  type: typeof SHOW_MOBILE_APP_MODAL;
}

interface HideMobileAppModalAction {
  type: typeof CLOSE_MOBILE_APP_MODAL;
}

export interface FileModalOptions {
  file?: BizzeyFile;
  onSubmit?: (file: BizzeyFile) => void;
}

interface ShowFileModalAction extends FileModalOptions {
  type: typeof SHOW_FILE_MODAL;
}

interface HideFileModalAction {
  type: typeof CLOSE_FILE_MODAL;
}

export interface MoveFilesModalOptions {
  fileIds: string[];
  onSubmit?: () => void;
}

interface ShowMoveFilesModalAction extends MoveFilesModalOptions {
  type: typeof SHOW_MOVE_FILES_MODAL;
}

interface HideMoveFilesModalAction {
  type: typeof CLOSE_MOVE_FILES_MODAL;
}

export interface ExportLedgerItemsModalOptions {
  onSubmit?: () => void;
}

interface ShowExportLedgerItemsModalAction
  extends ExportLedgerItemsModalOptions {
  type: typeof SHOW_EXPORT_LEDGER_ITEMS_MODAL;
}

interface HideExportLedgerItemsModalAction {
  type: typeof CLOSE_EXPORT_LEDGER_ITEMS_MODAL;
}

export interface ExportExpensesModalOptions {
  onSubmit?: () => void;
}

interface ShowExportExpensesModalAction extends ExportExpensesModalOptions {
  type: typeof SHOW_EXPORT_EXPENSES_MODAL;
}

interface HideExportExpensesModalAction {
  type: typeof CLOSE_EXPORT_EXPENSES_MODAL;
}

export interface ExpenseCategoryModalOptions {
  category?: ExpenseCategory;
  onSubmit?: (category: ExpenseCategory, deleted?: boolean) => void;
}

interface ShowExpenseCategoryModalAction extends ExpenseCategoryModalOptions {
  type: typeof SHOW_EXPENSE_CATEGORY_MODAL;
}

interface HideExpenseCategoryModalAction {
  type: typeof CLOSE_EXPENSE_CATEGORY_MODAL;
}

export interface ExportProductsModalOptions {
  onSubmit?: () => void;
}

interface ShowExportProductsModalAction extends ExportProductsModalOptions {
  type: typeof SHOW_EXPORT_PRODUCTS_MODAL;
}

interface HideExportProductsModalAction {
  type: typeof CLOSE_EXPORT_PRODUCTS_MODAL;
}

export interface CustomFieldModalOptions {
  customField?: CustomField;
  modelDisabled?: boolean;
  typeDisabled?: boolean;
  onSubmit?: (customField: CustomField) => void;
}

interface ShowCustomFieldModalAction extends CustomFieldModalOptions {
  type: typeof SHOW_CUSTOM_FIELD_MODAL;
}

interface HideCustomFieldModalAction {
  type: typeof CLOSE_CUSTOM_FIELD_MODAL;
}

export interface TaskModalOptions {
  task?: Task;
  contactDisabled?: boolean;
  projectDisabled?: boolean;
  onSubmit?: (task: Task) => void;
  onDelete?: (task: Task) => void;
}

export interface BoardModalOptions {
  board?: Board;
  contactDisabled?: boolean;
  projectDisabled?: boolean;
  onSubmit?: (board: Board) => void;
}
interface ShowBoardModalAction extends BoardModalOptions {
  type: typeof SHOW_BOARD_MODAL;
}

interface HideBoardModalAction {
  type: typeof CLOSE_BOARD_MODAL;
}

interface ShowTaskModalAction extends TaskModalOptions {
  type: typeof SHOW_TASK_MODAL;
}

interface HideTaskModalAction {
  type: typeof CLOSE_TASK_MODAL;
}

export interface CalendarEventModalOptions {
  calendarEvent?: CalendarEvent;
  contactDisabled?: boolean;
  projectDisabled?: boolean;
  onSubmit?: (event: CalendarEvent) => void;
  onDelete?: (eventId: string) => void;
}
interface ShowCalendarEventModalAction extends CalendarEventModalOptions {
  type: typeof SHOW_CALENDAR_EVENT_MODAL;
}

interface HideCalendarEventModalAction {
  type: typeof CLOSE_CALENDAR_EVENT_MODAL;
}

export interface AttachmentsViewerOptions {
  activeIndex?: number;
  attachments?: PreviewAttachment[];
  actions?: AttachmentViewerAction[];
}

interface ShowAttachmentsViewerModalAction extends AttachmentsViewerOptions {
  type: typeof SHOW_ATTACHMENTS_VIEWER_MODAL;
}

interface HideAttachmentsViewerModalAction {
  type: typeof CLOSE_ATTACHMENTS_VIEWER_MODAL;
}

export interface AddAccountantModalOptions {
  onSubmit?: (accountant: Accountant) => void;
}
interface ShowAddAccountantModalAction extends AddAccountantModalOptions {
  type: typeof SHOW_ADD_ACCOUNTANT_MODAL;
}

interface HideAddAccountantModalAction {
  type: typeof CLOSE_ADD_ACCOUNTANT_MODAL;
}

export interface UserWorkspaceSettingModalOptions {
  activeTab?: UserWorkspaceSettingModalTab;
  userWorkspaceSetting?: UserWorkspaceSetting;
  onSubmit?: (userWorkspaceSetting: UserWorkspaceSetting) => void;
}
interface ShowUserWorkspaceSettingModalAction
  extends UserWorkspaceSettingModalOptions {
  type: typeof SHOW_USER_WORKSPACE_SETTING_MODAL;
}

interface HideUserWorkspaceSettingModalAction {
  type: typeof CLOSE_USER_WORKSPACE_SETTING_MODAL;
}

export interface BulkTimeEntryModalOptions {
  contactId?: string;
  contactDisabled?: boolean;
  projectId?: string;
  projectDisabled?: boolean;
  onSubmit?: (timeEntries: TimeEntry[]) => void;
}
interface ShowBulkTimeEntryModalAction extends BulkTimeEntryModalOptions {
  type: typeof SHOW_BULK_TIME_ENTRY_MODAL;
}

interface HideBulkTimeEntryModalAction {
  type: typeof CLOSE_BULK_TIME_ENTRY_MODAL;
}

export interface CalculateAmountModalOptions {
  title?: string;
  amount: number;
  onSubmit?: (advanceAmount: number) => void;
}
interface ShowCalculateAmountModalAction extends CalculateAmountModalOptions {
  type: typeof SHOW_CALCULATE_ADVANCE_MODAL;
}

interface HideCalculateAmountModalAction {
  type: typeof CLOSE_CALCULATE_ADVANCE_MODAL;
}

export interface ShowSendContractModalOptions {
  id?: string;
  onSubmit?: () => void;
}

interface ShowSendContractModalAction extends ShowSendContractModalOptions {
  type: typeof SHOW_SEND_CONTRACT_MODAL;
}

interface HideSendContractModalAction {
  type: typeof CLOSE_SEND_CONTRACT_MODAL;
}

export interface ShowShareLinksModalOptions {
  title?: string;
  shareableLinks: ShareableLink[];
}

interface ShowShareLinksModalAction extends ShowShareLinksModalOptions {
  type: typeof SHOW_SHARE_LINKS_MODAL;
}

interface HideShareLinksModalAction {
  type: typeof CLOSE_SHARE_LINKS_MODAL;
}

export interface ShowSendProposalModalOptions {
  id?: string;
  onSubmit?: () => void;
}

interface ShowSendProposalModalAction extends ShowSendProposalModalOptions {
  type: typeof SHOW_SEND_PROPOSAL_MODAL;
}

interface HideSendProposalModalAction {
  type: typeof CLOSE_SEND_PROPOSAL_MODAL;
}

export interface ShowItemsBlockSettingModalOptions {
  block: ItemsBlock;
  onSubmit?: (block: ItemsBlock) => void;
}

export interface ShowItemsBlockSettingModalAction
  extends ShowItemsBlockSettingModalOptions {
  type: typeof SHOW_ITEMS_BLOCK_SETTING_MODAL;
}

export interface HideItemsBlockSettingModalAction {
  type: typeof CLOSE_ITEMS_BLOCK_SETTING_MODAL;
}

export interface ShowItemBlockSettingModalOptions {
  itemBlock: ItemBlock;
  onSubmit?: (itemBlock: ItemBlock) => void;
}

export interface ShowItemBlockSettingModalAction
  extends ShowItemBlockSettingModalOptions {
  type: typeof SHOW_ITEM_BLOCK_SETTING_MODAL;
}

export interface HideItemBlockSettingModalAction {
  type: typeof CLOSE_ITEM_BLOCK_SETTING_MODAL;
}

export interface ShowDirectionsModalOptions {
  origin?: string;
  destination?: string;
  onSubmit?: (result: DirectionsModalResult) => void;
}

export interface DirectionsModalResult {
  distance: number;
}

export interface ShowDirectionsModalAction extends ShowDirectionsModalOptions {
  type: typeof SHOW_DIRECTIONS_MODAL;
}

export interface HideDirectionsModalAction {
  type: typeof CLOSE_DIRECTIONS_MODAL;
}

export interface LedgerItemPaymentModalOptions {
  ledgerItemPaymentId?: string;
  ledgerItemId: string;
  onSubmit?: (ledgerItemPayment: LedgerItemPayment) => void;
}

interface ShowLedgerItemPaymentModalAction
  extends LedgerItemPaymentModalOptions {
  type: typeof SHOW_LEDGER_ITEM_PAYMENT_MODAL;
}

interface HideLedgerItemPaymentModalAction {
  type: typeof CLOSE_LEDGER_ITEM_PAYMENT_MODAL;
}

export interface LedgerItemTransactionHistoryModalOptions {
  ledgerItemId: string;
  onSync?: () => void;
}

interface ShowLedgerItemTransactionHistoryModalAction
  extends LedgerItemTransactionHistoryModalOptions {
  type: typeof SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL;
}

interface HideLedgerItemTransactionHistoryModalAction {
  type: typeof CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL;
}

export interface ledgerItemClaimModalOptions {
  ledgerItemId: string;
  onSubmit?: () => void;
}

interface ShowledgerItemClaimModalAction extends ledgerItemClaimModalOptions {
  type: typeof SHOW_LEDGER_ITEM_CLAIM_MODAL;
}

interface HideledgerItemClaimModalAction {
  type: typeof CLOSE_LEDGER_ITEM_CLAIM_MODAL;
}

export interface PaymentModalOptions {
  payment: Payment;
  onSubmit?: (payment: Payment) => void;
}

interface ShowPaymentModalAction extends PaymentModalOptions {
  type: typeof SHOW_PAYMENT_MODAL;
}

interface HidePaymentModalAction {
  type: typeof CLOSE_PAYMENT_MODAL;
}

export interface PaymentInitiationModalOptions {
  paymentInitiation: PaymentInitiation;
  onSubmit?: (paymentInitiation: PaymentInitiation) => void;
  onDelete?: (paymentInitiation: PaymentInitiation) => void;
}

interface ShowPaymentInitiationModalAction
  extends PaymentInitiationModalOptions {
  type: typeof SHOW_PAYMENT_INITIATION_MODAL;
}

interface HidePaymentInitiationModalAction {
  type: typeof CLOSE_PAYMENT_INITIATION_MODAL;
}

export interface DealModalOptions {
  deal: Deal;
  contactDisabled?: boolean;
  projectDisabled?: boolean;
  activeTab?: DealModalTab;
  onSubmit?: (deal: Deal) => void;
}

interface ShowDealModalAction extends DealModalOptions {
  type: typeof SHOW_DEAL_MODAL;
}

interface HideDealModalAction {
  type: typeof CLOSE_DEAL_MODAL;
}

export interface DealStageModalOptions {
  dealStage: DealStage;
  onSubmit?: (dealStage: DealStage) => void;
}

interface ShowDealStageModalAction extends DealStageModalOptions {
  type: typeof SHOW_DEAL_STAGE_MODAL;
}

interface HideDealStageModalAction {
  type: typeof CLOSE_DEAL_STAGE_MODAL;
}

export interface ContentBlockTemplateModalOptions {
  contentBlockTemplate: ContentBlockTemplate;
  contentBlockId: string;
  onSubmit?: (contentBlockTemplate: ContentBlockTemplate) => void;
  onDelete?: (contentBlockTemplate: ContentBlockTemplate) => void;
}

interface ShowContentBlockTemplateModalAction
  extends ContentBlockTemplateModalOptions {
  type: typeof SHOW_CONTENT_BLOCK_TEMPLATE_MODAL;
}

interface HideContentBlockTemplateModalAction {
  type: typeof CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL;
}

export interface TaxModalOptions {
  tax?: WorkspaceTax;
  onSubmit?: (tax: WorkspaceTax) => void;
}

interface ShowTaxModalAction extends TaxModalOptions {
  type: typeof SHOW_TAX_MODAL;
}

interface HideTaxModalAction {
  type: typeof CLOSE_TAX_MODAL;
}

export interface WorkTypeModalOptions {
  workType?: WorkType;
  onSubmit?: (workType: WorkType) => void;
}

interface ShowWorkTypeModalAction extends WorkTypeModalOptions {
  type: typeof SHOW_WORK_TYPE_MODAL;
}

interface HideWorkTypeModalAction {
  type: typeof CLOSE_WORK_TYPE_MODAL;
}

export interface DiscountModalOptions {
  currency: string;
  total: number;
  discount?: number;
  onSubmit?: (discount: number) => void;
}

interface ShowDiscountModalAction extends DiscountModalOptions {
  type: typeof SHOW_DISCOUNT_MODAL;
}

interface HideDiscountModalAction {
  type: typeof CLOSE_DISCOUNT_MODAL;
}

export interface PeppolParticipantModalOptions {
  query?: string;
  onSubmit?: (peppolParticipant: PeppolParticipant) => void;
}

interface ShowPeppolParticipantModalAction
  extends PeppolParticipantModalOptions {
  type: typeof SHOW_PEPPOL_PARTICIPANT_MODAL;
}

interface HidePeppolParticipantModalAction {
  type: typeof CLOSE_PEPPOL_PARTICIPANT_MODAL;
}

export interface FinancialYearModalOptions {
  financialYear?: FinancialYear;
  onSubmit?: (financialYear: FinancialYear) => void;
}

interface ShowFinancialYearModalAction extends FinancialYearModalOptions {
  type: typeof SHOW_FINANCIAL_YEAR_MODAL;
}

interface HideFinancialYearModalAction {
  type: typeof CLOSE_FINANCIAL_YEAR_MODAL;
}

export interface TransactionModalOptions {
  transaction?: Transaction;
  activeTab?: TransactionModalTab;
  onSubmit?: (transaction: Transaction) => void;
}

interface ShowTransactionModalAction extends TransactionModalOptions {
  type: typeof SHOW_TRANSACTION_MODAL;
}

interface HideTransactionModalAction {
  type: typeof CLOSE_TRANSACTION_MODAL;
}

export interface ShowQuestionAndAnswerBlockSettingModalOptions {
  block: QuestionAndAnswerBlock;
  onSubmit?: (block: QuestionAndAnswerBlock) => void;
}

export interface ShowQuestionAndAnswerBlockSettingModalAction
  extends ShowQuestionAndAnswerBlockSettingModalOptions {
  type: typeof SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL;
}

export interface HideQuestionAndAnswerBlockSettingModalAction {
  type: typeof CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL;
}

export interface ShowPlaybookSubmissionModalOptions {
  playbookSubmission: PlaybookSubmission;
  onSubmit?: (
    playbookSubmission: PlaybookSubmission,
    call?: Call,
    task?: Task
  ) => void;
}

export interface ShowPlaybookSubmissionModalAction
  extends ShowPlaybookSubmissionModalOptions {
  type: typeof SHOW_PLAYBOOK_SUBMISSION_MODAL;
}

export interface HidePlaybookSubmissionModalAction {
  type: typeof CLOSE_PLAYBOOK_SUBMISSION_MODAL;
}

export interface ShowBulkProjectStatusUpdateModalOptions {
  excludeStatuses?: ProjectStatus[];
  onSubmit?: (status: ProjectStatus) => void;
}

export interface ShowBulkProjectStatusUpdateModalAction
  extends ShowBulkProjectStatusUpdateModalOptions {
  type: typeof SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL;
}

export interface HideBulkProjectStatusUpdateModalAction {
  type: typeof CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL;
}

export interface ShowSignatureModalOptions {
  onSubmit?: (signature: SignatureResult) => void;
  onClose?: () => void;
}

export interface ShowSignatureModalAction extends ShowSignatureModalOptions {
  type: typeof SHOW_SIGNATURE_MODAL;
}

export interface HideSignatureModalAction {
  type: typeof CLOSE_SIGNATURE_MODAL;
}

export interface EmailViewerModalOptions {
  email?: Email;
}

interface ShowEmailViewerModalAction extends EmailViewerModalOptions {
  type: typeof SHOW_EMAIL_VIEWER_MODAL;
}

interface HideEmailViewerModalAction {
  type: typeof CLOSE_EMAIL_VIEWER_MODAL;
}

export interface ContactGroupModalOptions {
  contactGroup?: ContactGroup;
  onSubmit?: (contactGroup: ContactGroup) => void;
}

interface ShowContactGroupModalAction extends ContactGroupModalOptions {
  type: typeof SHOW_CONTACT_GROUP_MODAL;
}

interface HideContactGroupModalAction {
  type: typeof CLOSE_CONTACT_GROUP_MODAL;
}

export interface BulkContactGroupModalOptions {
  action: "add" | "remove";
  contactIds: string[];
  onSubmit?: (contactGroups: ContactGroup[]) => void;
}

interface ShowBulkContactGroupModalAction extends BulkContactGroupModalOptions {
  type: typeof SHOW_BULK_CONTACT_GROUP_MODAL;
}

interface HideBulkContactGroupModalAction {
  type: typeof CLOSE_BULK_CONTACT_GROUP_MODAL;
}

export interface ExportContactsModalOptions {
  onSubmit?: () => void;
}

interface ShowExportContactsModalAction extends ExportContactsModalOptions {
  type: typeof SHOW_EXPORT_CONTACTS_MODAL;
}

interface HideExportContactsModalAction {
  type: typeof CLOSE_EXPORT_CONTACTS_MODAL;
}

export interface ContactTypeModalOptions {
  onSubmit?: (contactType: ContactType) => void;
}

interface ShowContactTypeModalAction extends ContactTypeModalOptions {
  type: typeof SHOW_CONTACT_TYPE_MODAL;
}

interface HideContactTypeModalAction {
  type: typeof CLOSE_CONTACT_TYPE_MODAL;
}

export interface FileUploadModalOptions {
  resourceId: string;
  resourceType: BizzeyFileResourceType;
  onSubmit?: (files: BizzeyFile[]) => void;
}

interface ShowFileUploadModalAction extends FileUploadModalOptions {
  type: typeof SHOW_FILE_UPLOAD_MODAL;
}

interface HideFileUploadModalAction {
  type: typeof CLOSE_FILE_UPLOAD_MODAL;
}

export interface PaymentQRModalOptions {
  name?: string;
  iban?: string;
  remittanceInformation?: string;
  remittanceInformationType?: RemittanceInformationType;
  amount?: number;
  currency?: string;
  onSubmit?: (paid: boolean) => void;
}

interface ShowPaymentQRModalAction extends PaymentQRModalOptions {
  type: typeof SHOW_PAYMENT_QR_MODAL;
}

interface HidePaymentQRModalAction {
  type: typeof CLOSE_PAYMENT_QR_MODAL;
}

export interface CustomComponentModalOptions {
  title: string;
  component: JSX.Element;
}

interface ShowCustomComponentModalAction extends CustomComponentModalOptions {
  type: typeof SHOW_CUSTOM_COMPONENT_MODAL;
}

interface HideCustomComponentModalAction {
  type: typeof CLOSE_CUSTOM_COMPONENT_MODAL;
}

export interface DocumentModalOptions {
  document?: Document;
  onSubmit?: (document: Document) => void;
}

interface ShowDocumentModalAction extends DocumentModalOptions {
  type: typeof SHOW_DOCUMENT_MODAL;
}

interface HideDocumentModalAction {
  type: typeof CLOSE_DOCUMENT_MODAL;
}

export interface DocumentTagModalOptions {
  documentTag?: DocumentTag;
  onSubmit?: (documentTag: DocumentTag) => void;
  onDelete?: (documentTag: DocumentTag) => void;
}

interface ShowDocumentTagModalAction extends DocumentTagModalOptions {
  type: typeof SHOW_DOCUMENT_TAG_MODAL;
}

interface HideDocumentTagModalAction {
  type: typeof CLOSE_DOCUMENT_TAG_MODAL;
}

export interface ExportDocumentsModalOptions {
  onSubmit?: () => void;
}

interface ShowExportDocumentsModalAction extends ExportDocumentsModalOptions {
  type: typeof SHOW_EXPORT_DOCUMENTS_MODAL;
}

interface HideExportDocumentsModalAction {
  type: typeof CLOSE_EXPORT_DOCUMENTS_MODAL;
}

export interface UploadDocumentsModalOptions {
  onSubmit?: (documents: Document[]) => void;
}

interface ShowUploadDocumentsModalAction extends UploadDocumentsModalOptions {
  type: typeof SHOW_UPLOAD_DOCUMENTS_MODAL;
}

interface HideUploadDocumentsModalAction {
  type: typeof CLOSE_UPLOAD_DOCUMENTS_MODAL;
}

export interface ProductImportModalOptions {
  onSubmit?: () => void;
}

interface ShowProductImportModalAction extends ProductImportModalOptions {
  type: typeof SHOW_PRODUCT_IMPORT_MODAL;
}

interface HideProductImportModalAction {
  type: typeof CLOSE_PRODUCT_IMPORT_MODAL;
}

export interface CallModalOptions {
  call?: Call;
  onSubmit?: (call: Call) => void;
}

interface ShowCallModalAction extends CallModalOptions {
  type: typeof SHOW_CALL_MODAL;
}

interface HideCallModalAction {
  type: typeof CLOSE_CALL_MODAL;
}

export interface ShowSendEmailModalOptions {
  email: SendEmailParams;
  onSubmit?: () => void;
}

interface ShowSendEmailModalAction extends ShowSendEmailModalOptions {
  type: typeof SHOW_SEND_EMAIL_MODAL;
}

interface HideSendEmailModalAction {
  type: typeof CLOSE_SEND_EMAIL_MODAL;
}

export interface ShowEmailTemplateModalOptions {
  emailTemplate: EmailTemplate;
  onSubmit?: (emailTemplate: EmailTemplate) => void;
}

interface ShowEmailTemplateModalAction extends ShowEmailTemplateModalOptions {
  type: typeof SHOW_EMAIL_TEMPLATE_MODAL;
}

interface HideEmailTemplateModalAction {
  type: typeof CLOSE_EMAIL_TEMPLATE_MODAL;
}

export type ModalsActionTypes =
  | ShowContactModalAction
  | HideContactModalAction
  | ShowProjectModalAction
  | HideProjectModalAction
  | ShowProjectStatusUpdateModalAction
  | HideProjectStatusUpdateModalAction
  | ShowExpenseModalAction
  | HideExpenseModalAction
  | ShowTimeEntryExportModalAction
  | HideTimeEntryExportModalAction
  | ShowTimeEntryModalAction
  | HideTimeEntryModalAction
  | ShowSendLedgerItemModalAction
  | HideSendLedgerItemModalAction
  | ShowPaymentDetailsModalAction
  | HidePaymentDetailsModalAction
  | ShowImportProductsModalAction
  | HideImportProductsModalAction
  | ShowImportTimeEntriesModalAction
  | HideImportTimeEntriesModalAction
  | ShowImportExpensesModalAction
  | HideImportExpensesModalAction
  | ShowTaxesModalAction
  | HideTaxesModalAction
  | ShowConfirmModalAction
  | HideConfirmModalAction
  | ShowProductModalAction
  | HideProductModalAction
  | ShowMobileAppModalAction
  | HideMobileAppModalAction
  | ShowFileModalAction
  | HideFileModalAction
  | ShowMoveFilesModalAction
  | HideMoveFilesModalAction
  | ShowExportLedgerItemsModalAction
  | HideExportLedgerItemsModalAction
  | ShowExportExpensesModalAction
  | HideExportExpensesModalAction
  | ShowExpenseCategoryModalAction
  | HideExpenseCategoryModalAction
  | ShowSettingsEmailTemplateModalAction
  | HideSettingsEmailTemplateModalAction
  | ShowExportProductsModalAction
  | HideExportProductsModalAction
  | ShowCustomFieldModalAction
  | HideCustomFieldModalAction
  | ShowBoardModalAction
  | HideBoardModalAction
  | ShowTaskModalAction
  | HideTaskModalAction
  | ShowCalendarEventModalAction
  | HideCalendarEventModalAction
  | ShowAttachmentsViewerModalAction
  | HideAttachmentsViewerModalAction
  | ShowAddAccountantModalAction
  | HideAddAccountantModalAction
  | ShowUserWorkspaceSettingModalAction
  | HideUserWorkspaceSettingModalAction
  | ShowBulkTimeEntryModalAction
  | HideBulkTimeEntryModalAction
  | ShowCalculateAmountModalAction
  | HideCalculateAmountModalAction
  | ShowSendContractModalAction
  | HideSendContractModalAction
  | ShowShareLinksModalAction
  | HideShareLinksModalAction
  | ShowSendProposalModalAction
  | HideSendProposalModalAction
  | ShowItemsBlockSettingModalAction
  | HideItemsBlockSettingModalAction
  | ShowItemBlockSettingModalAction
  | HideItemBlockSettingModalAction
  | ShowDirectionsModalAction
  | HideDirectionsModalAction
  | ShowLedgerItemPaymentModalAction
  | HideLedgerItemPaymentModalAction
  | ShowLedgerItemTransactionHistoryModalAction
  | HideLedgerItemTransactionHistoryModalAction
  | ShowledgerItemClaimModalAction
  | HideledgerItemClaimModalAction
  | ShowPaymentModalAction
  | HidePaymentModalAction
  | ShowPaymentInitiationModalAction
  | HidePaymentInitiationModalAction
  | ShowDealModalAction
  | HideDealModalAction
  | ShowDealStageModalAction
  | HideDealStageModalAction
  | ShowContentBlockTemplateModalAction
  | HideContentBlockTemplateModalAction
  | ShowTaxModalAction
  | HideTaxModalAction
  | ShowWorkTypeModalAction
  | HideWorkTypeModalAction
  | ShowDiscountModalAction
  | HideDiscountModalAction
  | ShowPeppolParticipantModalAction
  | HidePeppolParticipantModalAction
  | ShowFinancialYearModalAction
  | HideFinancialYearModalAction
  | ShowTransactionModalAction
  | HideTransactionModalAction
  | ShowQuestionAndAnswerBlockSettingModalAction
  | HideQuestionAndAnswerBlockSettingModalAction
  | ShowPlaybookSubmissionModalAction
  | HidePlaybookSubmissionModalAction
  | ShowBulkProjectStatusUpdateModalAction
  | HideBulkProjectStatusUpdateModalAction
  | ShowSignatureModalAction
  | HideSignatureModalAction
  | ShowEmailViewerModalAction
  | HideEmailViewerModalAction
  | ShowContactGroupModalAction
  | HideContactGroupModalAction
  | ShowBulkContactGroupModalAction
  | HideBulkContactGroupModalAction
  | ShowExportContactsModalAction
  | HideExportContactsModalAction
  | ShowContactTypeModalAction
  | HideContactTypeModalAction
  | ShowFileUploadModalAction
  | HideFileUploadModalAction
  | ShowPaymentQRModalAction
  | HidePaymentQRModalAction
  | ShowCustomComponentModalAction
  | HideCustomComponentModalAction
  | ShowDocumentModalAction
  | HideDocumentModalAction
  | ShowDocumentTagModalAction
  | HideDocumentTagModalAction
  | ShowExportDocumentsModalAction
  | HideExportDocumentsModalAction
  | ShowUploadDocumentsModalAction
  | HideUploadDocumentsModalAction
  | ShowProductImportModalAction
  | HideProductImportModalAction
  | ShowCallModalAction
  | HideCallModalAction
  | ShowSendEmailModalAction
  | HideSendEmailModalAction
  | ShowEmailTemplateModalAction
  | HideEmailTemplateModalAction;
